import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useFelaEnhanced } from 'hooks';

import { NewElevatorButton } from 'modules/elevator-new';
import { useElevators } from 'modules/entities/modules/elevators';

import * as felaRules from './HomepageElevators.rules';

export const HomepageElevators = () => {
    const { styles } = useFelaEnhanced(felaRules);

    const { elevators, loading } = useElevators();

    return (
        <div className={styles.container}>
            <span className={styles.portfolio}>
                <FormattedMessage
                    id="page.home.elevators"
                    values={{
                        count: <span className={styles.count}>({loading ? '?' : elevators.length})</span>,
                    }}
                />
            </span>
            <NewElevatorButton />
            {/* <NewIncidentButton /> */}
        </div>
    );
};

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { useFelaEnhanced } from 'hooks';
import { ActiveIncidentsButton } from 'modules/active-incidents/components/ActiveIncidentsButton';
import ActiveIncidentsList from 'modules/active-incidents/components/ActiveIncidentsList';
import { useIncidents } from 'modules/entities/modules/incidents';
import { Button } from 'modules/ui';
import config from 'config';
import { generatePath } from 'react-router-dom';

import * as felaRules from './IncidentsWidget.rules';

const IncidentsWidget = () => {
    const { styles } = useFelaEnhanced(felaRules);

    const { activeIncidents, loading, error, unvalidatedIncidents } = useIncidents();

    return (
        <>
            <div className={styles.container}>
                <span className={styles.portfolio}>
                    <FormattedMessage
                        id="page.home.activeIncidents"
                        values={{
                            count: <span className={styles.count}>({loading ? '?' : activeIncidents.length + unvalidatedIncidents.length})</span>,
                        }}
                    />
                </span>
                <ActiveIncidentsButton size="large" />
            </div>
            <ActiveIncidentsList error={error} activeIncidents={activeIncidents} loading={loading} unvalidatedIncidents={unvalidatedIncidents} />
            <div className={styles.buttonContainer}>
                <Link to={generatePath(config.routes.incidents)}>
                    <Button className={styles.seeAllButton} onClick={() => {}}>
                        <FormattedMessage id="elevator.history.seeAll" />
                    </Button>
                </Link>
            </div>
        </>
    );
};

export default IncidentsWidget;

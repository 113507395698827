import React from 'react';
import { useIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';
import { Authenticated } from '@ackee/petrus';

import config from 'config';
import { Icon, IconType } from 'modules/ui';
import { useLogoutMessage } from 'modules/auth';
import { Amplify } from 'modules/amplify';
import { useAnalytics } from 'modules/analytics';

import { NewElevatorDialog, NewElevatorDialogProvider } from 'modules/elevator-new';

import Layout from './Layout';
import Routes from './Routes';
import AuthRoutes from './AuthRoutes';
import { NewIncidentDialog, GlobalDialogProvider } from 'modules/incident-new';
import SpecialRoutes from './SpecialRoutes/SpecialRoutes';
import { useLocation } from 'react-router-dom';
import { SupportDialog } from 'modules/support/components/SupportDialog';
import { ValidateConfirmDialog } from 'modules/incidents/components/ValidateConfirmDialog';

const getNavItems = (intl: IntlShape, isAdmin: boolean) => [
    {
        name: intl.formatMessage({ id: 'page.home.title' }),
        path: config.routes.home,
        icon: <Icon type={IconType.HOME} />,
        key: config.routes.home,
    },
    {
        name: intl.formatMessage({ id: 'page.elevators.title' }),
        path: config.routes.elevators,
        icon: <Icon type={IconType.ELEVATOR} />,
        key: config.routes.elevators,
    },
    // {
    //     name: intl.formatMessage({ id: 'page.iot_overview.title' }),
    //     path: '/iot_overview',
    //     icon: <Icon type={Icon.Types.IOT} />,
    //     key: '/iot_overview',
    // },
    // {
    //     name: intl.formatMessage({ id: 'page.reports.title' }),
    //     path: '/reports',
    //     icon: <Icon type={Icon.Types.REPORT} />,
    //     key: '/reports',
    // },
    {
        name: intl.formatMessage({ id: 'page.documents.title' }),
        path: config.routes.documents,
        icon: <Icon type={IconType.DOCUMENT} />,
        key: config.routes.documents,
    },
    // {
    //     name: intl.formatMessage({ id: 'page.contracts.title' }),
    //     path: '/contracts',
    //     icon: <Icon type={Icon.Types.CONTRACT} />,
    //     key: '/contracts',
    // },
    // {
    //     name: intl.formatMessage({ id: 'page.financials.title' }),
    //     path: '/financials',
    //     icon: <Icon type={Icon.Types.FINANCIALS} />,
    //     key: '/financials',
    // },
    // {
    //     name: intl.formatMessage({ id: 'page.operator_obligations.title' }),
    //     path: '/obligations',
    //     icon: <Icon type={Icon.Types.SHIELD} />,
    //     key: '/obligations',
    // },
    // {
    //     name: intl.formatMessage({ id: 'page.tasks.title' }),
    //     path: '/tasks',
    //     icon: <Icon type={Icon.Types.TASKS} />,
    //     key: '/tasks',
    // },

    {
        name: intl.formatMessage({ id: 'page.uploadZUES.menuTitle' }),
        path: config.routes.uploadZUES,
        icon: <Icon type={IconType.ZUES_PRICE_CHECK} />,
        key: config.routes.uploadZUES,
    },
    {
        name: intl.formatMessage({ id: 'page.uploadZUESDeficiency.menuTitle' }),
        path: config.routes.uploadZUESDeficiency,
        icon: <Icon type={IconType.ZUES_DEFICIENCY_CHECK} />,
        key: config.routes.uploadZUESDeficiency,
    },
    {
        name: intl.formatMessage({ id: 'page.uploadQuotation.menuTitle' }),
        path: config.routes.uploadQuotation,
        icon: <Icon type={IconType.QUOTATION_CHECK} />,
        key: config.routes.uploadQuotation,
    },
    {
        name: intl.formatMessage({ id: 'page.uploadContract.menuTitle' }),
        path: config.routes.uploadContract,
        icon: <Icon type={IconType.CONTRACT_CHECK} />,
        key: config.routes.uploadContract,
    },
    {
        name: intl.formatMessage({ id: 'page.fakePages.maintenanceAnalysis.menuTitle' }),
        path: config.routes.maintenanceAnalysis,
        icon: <Icon type={IconType.MAINTENANCE_ANALYSIS} />,
        key: config.routes.maintenanceAnalysis,
    },
    {
        name: intl.formatMessage({ id: 'page.fakePages.operatorObligations.menuTitle' }),
        path: config.routes.operatorObligations,
        icon: <Icon type={IconType.OPERATOR_OBLIGATION} />,
        key: config.routes.operatorObligations,
    },
    {
        name: intl.formatMessage({ id: 'page.support.title' }),
        path: '/support',
        icon: <Icon type={IconType.SUPPORT} />,
        key: '/support',
    },
    isAdmin && {
        name: intl.formatMessage({ id: 'page.workorders.title' }),
        path: '/work-orders',
        icon: <Icon type={IconType.TASKS} />,
        key: '/work-orders',
    },
];
const getItemsOutsideLayout = () => [config.routes.browserIssues];
const App = () => {
    const intl = useIntl();
    useAnalytics();
    useLogoutMessage();
    const location = useLocation();
    console.log(location.search)
    const isAdmin = location.search.includes("elevoniq-modifi");
    return (
        <Amplify>
            <Authenticated FallbackComponent={Routes}>
                <NewElevatorDialogProvider>
                    <GlobalDialogProvider>
                        <SpecialRoutes />
                        {!getItemsOutsideLayout().includes(location.pathname) && (
                            <Layout menuItems={getNavItems(intl, isAdmin)}>
                                <AuthRoutes />
                            </Layout>
                        )}
                        <NewElevatorDialog />
                        <NewIncidentDialog />
                        <SupportDialog />
                        <ValidateConfirmDialog/>
                    </GlobalDialogProvider>
                </NewElevatorDialogProvider>
            </Authenticated>
        </Amplify>
    );
};


export default App;

import { Auth } from 'aws-amplify';

import { history } from 'modules/core/modules/router/config';
import config from 'config';

import { LoginFormValues, AuthChallenges } from '../../types';
import { USER_EMAIL_STORAGE_KEY, USER_TEMP_PASSWORD } from '../../constants';
import createExpirationDate from './utils/createExpirationDate';
import mapUserData from './utils/mapUserData';
import { put, takeEvery } from 'redux-saga/effects';
import { loginRequest, loginRequestFailure, loginRequestRequest, loginRequestSuccess } from '../action';

export default function* authenticate({ email, password, disableRedirect }: LoginFormValues) {
    const user = yield Auth.signIn(email, password);
    if (user.challengeName === AuthChallenges.NEW_PASSWORD_REQUIRED) {
        localStorage.setItem(USER_EMAIL_STORAGE_KEY, email);
        localStorage.setItem(USER_TEMP_PASSWORD, password);
        if (!disableRedirect) history.push(config.routes.createPassword);
    }
    const userData = yield mapUserData(user);

    const session = yield Auth.currentSession();
    const accessToken = session.getAccessToken();
    const expiration = createExpirationDate(accessToken.payload.exp);
    // Get access token, send the cognito token

    return {
        tokens: {
            accessToken: {
                token: accessToken.getJwtToken(),
                expiration,
            },
            refreshToken: {
                token: null,
            },
        },
        changePasswordRequired: USER_EMAIL_STORAGE_KEY,
        user: userData,
    };
}

function* loginRequestHandler(action: ReturnType<typeof loginRequest>) {
    yield put(loginRequestRequest(action.meta.email));

    try {
        const payload = action.payload
        const user = yield Auth.signIn(payload.email, payload.password);
        if (user.challengeName === AuthChallenges.NEW_PASSWORD_REQUIRED) {
            
        }

        yield put(loginRequestSuccess(action.meta.email));
    } catch (error) {

        yield put(loginRequestFailure(error, action.meta.email));
    }
}

export function* loginRequestSaga() {
    yield takeEvery(loginRequest.toString(), loginRequestHandler);
}

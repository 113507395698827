import { all } from 'redux-saga/effects';

import { saga as elevators } from '../modules/elevators';
import { saga as maintenances } from '../modules/maintenances';
import { saga as incidents } from '../modules/incidents';
import { saga as trips } from '../modules/trips';
import { saga as states } from '../modules/elevator-states';
import { saga as coordinates } from '../modules/elevator-coordinates';
import { saga as floorActivity } from '../modules/elevator-floor-activity';
import { saga as elevatorState } from '../modules/elevator-state';
import { saga as invoices } from '../modules/documents-invoices';
import { saga as documents } from '../modules/documents';
import { saga as contracts } from '../modules/documents-contracts';
import { saga as otherDocuments } from '../modules/other-documents';
import { saga as workorderDocuments } from '../modules/workorder-documents';
import { saga as quotations } from '../modules/documents-quotations';
import { saga as assessmentReports } from '../modules/documents-assessment-reports';
import { saga as iotConfig } from '../modules/config';
import { saga as supportRequest } from '../modules/support';
import { saga as closedIncidents } from '../modules/closed-incidents';
import { saga as workorders } from '../modules/workorders';

export default function* entitiesSaga() {
    yield all([
        elevators(),
        incidents(),
        trips(),
        states(),
        coordinates(),
        floorActivity(),
        invoices(),
        documents(),
        contracts(),
        otherDocuments(),
        workorderDocuments(),
        quotations(),
        assessmentReports(),
        iotConfig(),
        supportRequest(),
        closedIncidents(),
        maintenances(),
        elevatorState(),
        workorders(),
    ]);
}

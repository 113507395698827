import React, { FC, ReactNode, ReactChildren } from 'react';

import * as serviceWorker from 'serviceWorker';
import { ErrorBoundary } from 'config/sentry';
import { UIMessageProvider, NotificationsProvider } from 'modules/ui';
import { MapProvider } from 'modules/map';

import { plugins } from '../config';

import { Fela } from '../modules/fela';
import { Redux } from '../modules/redux';
import { Router } from '../modules/router';

import Enhancers from '../containers/Enhancers';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
plugins.serviceWorker ? serviceWorker.register({}) : serviceWorker.unregister();

const Core: FC<{ children: ReactNode | ReactChildren }> = ({ children }) => {
    return (
        // Render nothing for providers but still provide error to sentry
        <ErrorBoundary fallback={null}>
            <Redux>
                <Fela>
                    <Router>
                        <Enhancers>
                            <UIMessageProvider>
                                <NotificationsProvider>
                                    <MapProvider>{children}</MapProvider>
                                </NotificationsProvider>
                            </UIMessageProvider>
                        </Enhancers>
                    </Router>
                </Fela>
            </Redux>
        </ErrorBoundary>
    );
};

export default Core;

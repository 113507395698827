import React, { FC } from 'react';
import { Switch, Route, Redirect, RouteProps } from 'react-router-dom';
import config from 'config/index';

import NoMatchPage from '../NoMatchPage';
import { routes as publicRoutes } from '../Routes';
import { ElevatorsPage } from 'modules/elevators';
import { MaintenancePage } from 'modules/maintenance';
import { ElevatorDetail, ElevatorHistory } from 'modules/elevator-detail';
import {
    DocumentsPage,
    UploadContractPage,
    UploadQuotationPage,
    UploadZUESPage,
    UploadZUESDeficiencyPage,
    GeneralDocumentUploadPage,
} from 'modules/documents';

import { FakePage, FakeType } from 'modules/fake-pages';
import { HomePage } from 'modules/home';
import { IncidentsPage } from 'modules/incidents';
import { SupportPage } from 'modules/support/components/SupportPage';
import { WorkOrdersPage } from 'modules/workOrders';

// If user comes to public route automatically redirect him to the default auth route
const redirectRoutes: Array<RouteProps> = [...publicRoutes, { path: config.routes.home }]
    .filter(route => route.path)
    .map(route => ({
        ...route,
        render: () => <Redirect to={config.routes.home} />,
    }));

const authRoutes: Array<RouteProps> = [
    {
        path: config.routes.home,
        exact: true,
        render: () => <HomePage />,
    },
    {
        path: config.routes.documents,
        exact: true,
        render: () => <Redirect to={config.routes.invoices} />,
    },
    {
        path: [
            config.routes.invoices,
            config.routes.contracts,
            config.routes.emergencyPlans,
            config.routes.riskAssessments,
            config.routes.conditionReports,
            config.routes.otherDocuments,
            config.routes.quotations,
            config.routes.assessmentReports,
        ],
        exact: true,
        render: () => <DocumentsPage />,
    },
    {
        path: config.routes.documentsUpload,
        exact: true,
        render: () => <GeneralDocumentUploadPage />,
    },

    {
        path: config.routes.elevators,
        exact: true,
        render: () => <ElevatorsPage />,
    },
    {
        path: [config.routes.incidents, config.routes.incidentsActive, config.routes.incidentsResolved],
        exact: true,
        render: () => <IncidentsPage />,
    },
    // {
    //     path: config.routes.incident,
    //     exact: true,
    //     render: () => <IncidentPage />,
    // },
    {
        path: [config.routes.support, config.routes.supportOpen, config.routes.supportClosed],
        exact: true,
        render: () => <SupportPage />,
    },
    {
        path: config.routes.elevatorDetail,
        exact: true,
        render: () => <ElevatorDetail />,
    },
    {
        path: config.routes.elevatorHistory,
        exact: true,
        render: () => <ElevatorHistory />,
    },
    {
        path: config.routes.login,
        exact: true,
        render: () => <Redirect to={config.routes.home} />,
    },
    {
        path: config.routes.registration,
        exact: true,
        render: () => <Redirect to={config.routes.home} />,
    },
    {
        path: config.routes.uploadContract,
        exact: true,
        render: () => <UploadContractPage />,
    },
    {
        path: config.routes.uploadQuotation,
        exact: true,
        render: () => <UploadQuotationPage />,
    },
    {
        path: config.routes.uploadZUES,
        exact: true,
        render: () => <UploadZUESPage />,
    },
    {
        path: config.routes.uploadZUESDeficiency,
        exact: true,
        render: () => <UploadZUESDeficiencyPage />,
    },
    {
        path: config.routes.operatorObligations,
        exact: true,
        render: () => <FakePage type={FakeType.OPERATOR_OBLIGATION} />,
    },
    {
        path: config.routes.maintenanceAnalysis,
        exact: true,
        render: () => <MaintenancePage />,
    },
    {
        path: config.routes.workOrders,
        exact: true,
        render: ({ location }) => {
            return location.search.includes('elevoniq-modifi') ? <WorkOrdersPage /> : <NoMatchPage />;
        },
    },
    {
        render: () => <NoMatchPage />,
    },
];

const routes = authRoutes.concat(redirectRoutes);

export interface AuthRoutesProps {}

const AuthRoutes: FC<AuthRoutesProps> = () => (
    <Switch>
        {routes.map((route, index) => (
            <Route key={String(route.path ?? index)} {...route} />
        ))}
    </Switch>
);

export default AuthRoutes;

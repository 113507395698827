import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { Tabs } from 'antd';

import { useFelaEnhanced } from 'hooks';

import { errorMessages, HTTPStatusCode, NotFound } from 'modules/errors';
import { SeoHead } from 'modules/seo';

import { UrlParam } from 'constants/index';

import { manipulateQueryString, omitQueryString } from 'services/utils';
import { useSearchParams } from 'services/hooks';

import HeaderCard from '../HeaderCard';
import IotDataTab from '../IotDataTab';
import TechnicalDataTab from '../TechnicalDataTab';
import ComponentsTab from '../ComponentsTab';
import { useElevatorDetail } from '../../hooks';
import { ElevatorDetailTabs } from '../../types';

import * as felaRules from './ElevatorDetail.styles';
import DocumentsTab from '../DocumentsTab';

const { TabPane } = Tabs;

type ElevatorDetailProps = {};

const tabPanes = [
    { id: ElevatorDetailTabs.IOT_DATA, name: 'page.elevatorDetail.tabs.iotData', render: <IotDataTab /> },
    {
        id: ElevatorDetailTabs.DOCUMENTS,
        name: 'page.elevatorDetail.tabs.documents',
        render: <DocumentsTab />,
    },
    {
        id: ElevatorDetailTabs.COMPONENTS,
        name: 'page.elevatorDetail.tabs.components',
        render: <ComponentsTab />,
    },
    {
        id: ElevatorDetailTabs.TECHNICAL_DATA,
        name: 'page.elevatorDetail.tabs.technicalData',
        render: <TechnicalDataTab />,
    },
];

const ElevatorDetail: FC<ElevatorDetailProps> = () => {
    const { styles } = useFelaEnhanced(felaRules);
    const history = useHistory();
    const { search } = useLocation();
    const activeTab = useSearchParams(UrlParam.TAB) as ElevatorDetailTabs;
    const { elevator, inProgress, error } = useElevatorDetail();

    const intl = useIntl();

    if (error === errorMessages.status[HTTPStatusCode.NotFound]) {
        return <NotFound />;
    }

    const onTabChange = key => {
        let newSearch = search;
        Object.values(UrlParam).forEach(item => {
            if (item !== UrlParam.TAB && search.includes(item)) {
                newSearch = omitQueryString(item, newSearch);
            }
        });
        history.push({ search: manipulateQueryString(key, UrlParam.TAB, newSearch) });
    };

    return (
        <>
            <SeoHead title={intl.formatMessage({ id: 'page.elevatorDetail.title' })} />
            <div className={styles.container}>
                <HeaderCard elevator={elevator} inProgress={inProgress} />
                {/* <Tabs
                    defaultActiveKey={activeTab ?? ElevatorDetailTabs.IOT_DATA}
                    className={styles.tabsWrapper}
                    id="elevator-detail-tabs"
                    onChange={onTabChange}
                    type="card"
                > */}
                <Tabs
                    defaultActiveKey={activeTab ?? ElevatorDetailTabs.IOT_DATA}
                    className={styles.tabsWrapper}
                    id="elevator-detail-tabs"
                    onChange={onTabChange}
                    
                >
                    {tabPanes.map(tab => (
                        <TabPane tab={<FormattedMessage id={tab.name} />} key={tab.id}>
                            {tab.render}
                        </TabPane>
                    ))}
                </Tabs>
            </div>
        </>
    );
};

export default ElevatorDetail;

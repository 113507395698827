import { Antonio } from '@ackee/antonio-core';
import { requestAuthHeaderInterceptor } from '@ackee/antonio-auth';

import config from 'config/config';

const authApi = new Antonio({
    baseURL: config.api.base,
});

authApi.interceptors.request.use(null, requestAuthHeaderInterceptor);

export { authApi };

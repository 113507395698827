import React, { FC, Suspense, lazy } from 'react';
import type { MenuDataItem } from '@ant-design/pro-layout';

import { useFelaEnhanced } from 'hooks';
import { Logo, Loader } from 'modules/ui';

import NavItem from '../NavItem';
import UserAccount from '../UserAccount';

import * as layoutStyles from './Layout.styles';
import { useLocation } from 'react-router-dom';
import { useFetchIotConfig } from 'modules/entities/modules/config/hooks';
import FeedbackButton from '../FeedbackButton/FeedbackButton';

const BasicLayout = lazy(() => import('@ant-design/pro-layout').then(({ BasicLayout }) => ({ default: BasicLayout })));

interface Props {
    menuItems: MenuDataItem[];
}

const Layout: FC<Props> = ({ menuItems, children }) => {
    const { styles, theme } = useFelaEnhanced(layoutStyles);
    useFetchIotConfig(); // Dispatching action to Fetch Global Configuration from AWS
    const { pathname } = useLocation();
    return (
        <Suspense fallback={<Loader show />}>
            <BasicLayout
                logo={<Logo />}
                title={null}
                navTheme="light"
                route={{ routes: menuItems }}
                className={pathname && pathname.includes('support-request') ? styles.layoutWhite : styles.layout}
                style={{ background: 'pink' }}
                menuItemRender={(props, item) => <NavItem to={props.path}>{item}</NavItem>}
                rightContentRender={() => (
                    <div className={styles.rightContent} >
                        <div className={styles.feedbackButton}>
                            <FeedbackButton />
                        </div>
                        <UserAccount />
                    </div>
                )}
                siderWidth={theme.width.asideNav}
                menuProps={{
                    className: styles.menu,
                }}
            >
                {children}
            </BasicLayout>
        </Suspense>
    );
};

export default Layout;

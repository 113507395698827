export enum AuthChallenges {
    NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
}

export enum ForgotPasswordSteps {
    EMAIL = 'email',
    PASSWORD = 'password',
}

export enum AuthErrorCodes {
    INVALID_PASSWORD_EXCEPTION = 'InvalidPasswordException',
}

export interface User {
    customUserId?: string;
    username: string;
    email?: string;
    avatar?: string;
}

export interface PetrusState {
    user?: User;
}

export interface LoginFormValues {
    email: string;
    password: string;
    disableRedirect?: boolean;
}

export interface RegisterFormValues {
    email: string;
}

export interface ConfirmPasswordFormValues {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}

export interface ForgottenPasswordEmailValues {
    email: string;
}

export interface ForgottenPasswordValues {
    email: string;
    confirmNewPassword: string;
    newPassword: string;
    code: string;
}

export enum RegistrationStatus {
    SUBMITTED = 'submitted',
    CREATED = 'created',
    FAILED = 'failed',
}


